<template>
<div>
  <el-row>
    <el-button @click="back" style="float: right;">
      返 回
    </el-button>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="12">
      <b-card v-loading="detailLoading">
        <el-descriptions title="请求概况">
          <el-descriptions-item label="请求编号">{{detailData.serialNumber}}</el-descriptions-item>
          <el-descriptions-item label="接收日期">{{detailData.receivedTime}}</el-descriptions-item>
          <el-descriptions-item label="接收人员">{{detailData.receivedUser}}</el-descriptions-item>
          <el-descriptions-item label="关联请求">{{detailData.relatedListStr}}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-descriptions title="个人信息主体情况">
          <el-descriptions-item label="姓名">{{detailData.userName}}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{detailData.userPhone}}</el-descriptions-item>
          <el-descriptions-item label="联络邮箱">{{detailData.userEmail}}</el-descriptions-item>
          <el-descriptions-item label="其他信息">{{detailData.userOtherInfo}}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-descriptions title="个人信息主体请求内容" :column="1">
          <el-descriptions-item label="涉及业务">{{detailData.productNames}}</el-descriptions-item>
          <el-descriptions-item label="涉及数据">{{detailData.dataInfo}}</el-descriptions-item>
          <el-descriptions-item label="请求权利类型">
            <div v-for="(rightType, index) of detailData.rightTypes" :key="index">
              <el-tooltip
                  placement="top"
                  effect="light"
                  :content="rightType.explain"
                  :disabled="rightType.explain.length <= 40"
              >
                <el-tag size="small">{{rightType.name}}</el-tag>
              </el-tooltip>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="请求具体内容">
            {{detailData.requestInfo}}
          </el-descriptions-item>
          <el-descriptions-item label="请求紧迫程度">{{levelMap[detailData.handleLevel]}}</el-descriptions-item>
          <el-descriptions-item label="附件">
            <span v-for="(annex, index) of detailData.annexList" :key="index">
              <el-link @click="downloadAnnex(annex.uid)">{{annex.fileName}}</el-link>
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </b-card>
    </el-col>
    <el-col :span="12">
      <b-card v-loading="handleDetailLoading">
        <b-row>
          <b-col><h2>流程处理情况</h2></b-col>
        </b-row>
        <div v-for="(node, index) of nodeList" :key="index">
          <el-descriptions :title="node.nodeName" v-if="node.handleStatus !== ''">
            <el-descriptions-item label="处理人">{{node.handleUserName}}</el-descriptions-item>
            <el-descriptions-item label="处理时间">{{node.handleTime}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{node.handleStatus}}</el-descriptions-item>
            <el-descriptions-item label="信息">{{node.handleMessage}}</el-descriptions-item>
            <el-descriptions-item label="附件">
              <span v-for="(annex, index) of node.annexList" :key="index">
                <el-link @click="downloadNodeAnnex(annex.id)">{{annex.fileName}}</el-link>
              </span>
            </el-descriptions-item>
          </el-descriptions>
          <el-divider v-if="node.handleStatus !== ''"></el-divider>
          <el-button v-if="node.handleUserId === userId && node.handleStatus === ''" type="primary" size="small" @click="handleNode">处理</el-button>
        </div>
      </b-card>
    </el-col>
  </el-row>
  <!-- 弹窗, 新增 / 修改 -->
  <edit-dialog v-if="editVisible" ref="editDialog" @refreshDataList="getHandleInfo"></edit-dialog>
</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { DownloadAnnex, DownloadNodeAnnex } from '@/api/custom'
import EditDialog from './right-request-handle.vue'

export default {
  components: { EditDialog },
  data() {
    return {
      baseUrl: window.g.baseUrl,
      detailLoading: false,
      handleDetailLoading: false,
      editVisible: false,
      total: 0,
      detailData: {
        id: 0,
        FlowId: 0,
        serialNumber: '',
        receivedTime: '',
        receivedUser: undefined,
        userName: '',
        userPhone: '',
        userEmail: '',
        userOtherInfo: '',
        dataInfo: '',
        requestInfo: '',
        handleLevel: '',
        rightTypes: '',
        productNames: '',
        annexList: [],
        relatedListStr: '',
      },
      nodeList: [],
      userId: 0,
      levelMap: {
        1: '非常紧急（1天内解决并给出合理解释）',
        2: '紧急（3天内解决并给出合理解释）',
        3: '高优先（7天内解决并给出合理解释）',
        4: '优先（15天内解决并给出合理解释）',
        5: '一般（30天内解决并给出合理解释）',
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.userId = userData.id
      this.queryParams = this.$route.query
      if (this.queryParams.id !== undefined && this.queryParams.id !== '') {
        this.getInfo()
        this.getHandleInfo()
      }
    },
    handleNode() {
      this.editVisible = true
      this.$nextTick(() => {
        const node = this.nodeList.find(item => item.handleUserId === this.userId && item.handleStatus === '')
        this.$refs.editDialog.init(node)
      })
    },
    // 获取信息
    getInfo() {
      this.detailLoading = true
      this.$http.get(`/custom/request/detail?id=${this.queryParams.id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.detailData = res.data
        }
        this.detailLoading = false
      }).catch(() => {
        this.detailLoading = false
      })
    },
    back() {
      const pageParams = {
        pageNo: this.queryParams.pageNo,
        pageSize: this.queryParams.pageSize,
      }
      this.$router.push({
        path: '/custom/list',
        query: pageParams,
      })
    },
    getHandleInfo() {
      this.handleDetailLoading = true
      this.$http.get(`/custom/request/handle/list?id=${this.queryParams.id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.nodeList = res.data
        }
        this.handleDetailLoading = false
      }).catch(() => {
        this.handleDetailLoading = false
      })
    },
    downloadAnnex(annexId) {
      DownloadAnnex(annexId)
    },
    downloadNodeAnnex(annexId) {
      DownloadNodeAnnex(annexId)
    },
  },
}
</script>

<style scoped>

</style>
