<template>
  <el-dialog
      :title="nodeName"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
  >
    <el-row>
      <span >信息提示：<br>
        <span style="white-space: pre-wrap;">{{nodeMsg}}</span><br>
      </span>
    </el-row>
    <el-form
        v-loading="loading"
        ref="form"
        :model="dataForm"
        label-width="120px"
        :rules="dataRule"
        style="margin-top: 10px;"
    >
      <el-form-item
          label="处理情况"
          prop="HandleMessage">
        <el-input type="textarea" v-model="dataForm.HandleMessage" placeholder="理由"></el-input>
      </el-form-item>
      <el-form-item
          label="状态"
          prop="handleStatus"
      >
        <el-select v-model="dataForm.handleStatus" clearable>
          <el-option
              v-for="(item,index) in stateList"
              :key="index"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          label="附件">
        <el-upload
            class="upload-demo"
            :action="serverPath+'/sys/upload/file'"
            :headers="headers"
            :on-success="uploadFileSuccess"
            :on-remove="removeFile"
            :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div
        slot="footer"
        class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      dataForm: {
        Id: 0,
        handleStatus: '',
        HandleMessage: '',
        annexList: [],
      },
      stateList: [],
      nodeName: '',
      nodeMsg: '',
      nodeId: 0,
      serverPath: window.g.baseUrl,
      fileList: [],
      headers: { 'x-token': '' },
    }
  },
  computed: {
    dataRule() {
      return {
        handleStatus: [
          { required: true, message: '状态不能为空。', trigger: 'blur' },
        ],
        HandleMessage: [
          { required: true, message: '处理信息不能为空。', trigger: 'blur' },
        ],
      }
    },
  },
  created() {
  },
  methods: {
    init(node) {
      this.dialogVisible = true
      this.headers['x-token'] = localStorage.getItem('accessToken')
      this.dataForm.id = node.id
      this.nodeName = node.nodeName
      this.nodeMsg = node.nodeMsg
      this.getNodeInfo(node.nodeId)
    },
    getNodeInfo(nodeId) {
      this.$http.get(`/flow/custom/node/info?id=${nodeId}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.stateList = res.data.stateList
          this.dataForm.handleStatus = ''
          this.nodeMsg = res.data.guide
          debugger
        }
      }).catch(() => {})
    },
    dialogClose() {
      this.dataForm = {
        Id: 0,
        handleStatus: '',
        HandleMessage: '',
        annexList: [],
      }
      this.fileList = []
    },
    uploadFileSuccess(response, file) {
      const { savePath, fileName } = response.data
      this.fileList.push({
        name: fileName,
        url: `${this.serverPath}/temp${savePath}`,
        savePath,
        fileName,
        uid: file.uid,
      })
    },
    removeFile(file) {
      this.fileList = this.fileList.filter(item => item.uid !== file.uid)
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.dataForm.annexList = this.fileList
          this.$http.post('/custom/request/download/handle/save', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.dialogVisible = false
                  this.loading = false
                  this.$emit('refreshDataList')
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
